<template>
  <window-content v-if="!loading && !errors && !success">
    <e-row class="flex col-grow">
      <e-col class="flex flex-center items-center justify-center">
        <u-icon color="grey" name="engine-warning" icon-style="light" type="fa" class="icon-font-150" />
      </e-col>
      <e-col style="max-width: 600px" class="flex flex-center items-center justify-center">

        <e-row>
          <e-col>
            <e-row class="m-b">
              <e-col>Você está prestes a <strong><u>mudar o status de um lote</u></strong>.
                <br>
                <span class="text-negative">Isto impactará até se estiver ocorrendo leilão.</span>
              </e-col>
            </e-row>

            <e-row>
              <e-col>
                <strong>Novo status:</strong>
                <br><br>
                <div class="inline-block wrapper-sm b-radius-3px bg-white">
                  <status-lote :lote="lote" />
                </div>
              </e-col>
            </e-row>
            <div v-if="Status.STATUS_QUE_PRECISA_DE_LANCES.includes(lote.status) && !lote.arremate" class="m-b">
              <erp-select size="2" placeholder="Selecione o lance vencedor" required :options="lances"
                          v-model="forcarVenda" />
            </div>
            <!--<e-btn class="text-blue-grey-9 erp-btn-size-md" label="Confirmar alteração de status" />-->
            <e-row>
              <e-col class="flex content-between">
                <u-btn label="Confirmar" color="positive" class="text-black" icon="check" @click="save" />
                <u-btn label="Cancelar" color="negative" class="m-l" @click="cancelar" />
              </e-col>
            </e-row>
          </e-col>
        </e-row>

      </e-col>
    </e-row>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Lance modificado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox,
  ErpBox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '../../../../utils/money'
// import {atualizaLance, findLance as find} from '../../../../domain/leiloes/services/index'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
// import ArrematanteInput from '../../../arrematante/helpers/input/ArrematanteInput'
// import LanceRegistroStatus from '../include/lote/LanceRegistroStatus'
import StatusLote from '../include/StatusLote'
import * as Status from '../../../../domain/leiloes/helpers/LoteStatus'

let mocLote = {
  id: null,
  status: null
}

export default {
  name: 'MudarStatusLoteWindow',
  props: ['lote'],
  directives: {money: VMoney},
  data () {
    return {
      money: REAL_BRL,
      loading: false,
      errors: null,
      success: false,
      status: null,
      lance: JSON.parse(JSON.stringify(mocLote)),
      forcarVenda: null,
      mock: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.lote.lances) {
        this.forcarVenda = this.lote.lances[0].id
      }
    })
    /*this.loading = true
    this.id && find(this.id)
      .then(response => {
        let data = response.data

        data.valor = 'R$ ' + this.$options.filters.moeda(data.valor || 0)
        data.data = this.$options.filters.formatDate(data.data.date, 'dd/MM/yyyy HH:mm:ss')

        if (data.arrematante && data.arrematante.id) {
          this.cache.arrematanteSelected = data.arrematante
          data.arrematante = data.arrematante.id
        }

        this.lance = data
        this.$nextTick(() => {
          this.loading = false
        })
      })
      .catch((error) => {
        this.$uloc.dialog({
          title: 'Falha ao carregar!',
          color: 'negative',
          message: `Não conseguimos carregar o lance, entre em contato com o administrador.`
        })
        this.loading = false
        this.errors = error.data
        console.error(error, error.data)
      })*/
  },
  computed: {
    Status () {
      return Status
    },
    lances () {
      if (!this.lote.lances) {
        return [{
          label: 'Sem lances',
          value: null
        }]
      }
      return this.lote.lances.slice().map(lance => {
        return {
          label: `R$ ${this.$options.filters.moeda(lance.valor)} (${lance.arrematante.apelido})`,
          value: lance.id
        }
      })
    }
  },
  watch: {},
  methods: {
    save () {
      console.log('Save')
      this.loading = true

      // data.extra = JSON.stringify(data.extra)

      this.status = 'Processando modificação no lance'
      this.comunicatorClass.alterarStatusLote(this.lote.id, this.lote.status)
        .then(response => {
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              this.loading = false
              this.success = false
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              this.$uloc.window.close(this.$root.wid)
            })
          }
          cbSuccess()
        })
        .catch(({response}) => {
          console.log(response)
          this.loading = false
          this.alertApiError(response)
        })
    },
    cancelar () {
      this.$uloc.window.emit(this.$root.wid, 'cancel')
      this.$uloc.window.close(this.$root.wid)
    }
  },
  components: {
    StatusLote,
    // ArrematanteInput,
    ECol,
    ERow,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    ErpCheckbox,
    ErpBox
  }
}
</script>
